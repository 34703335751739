var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.form.detailList, function (detail) {
        return _c("div", { key: detail.salDetailValue }, [
          _vm.form.detailList.length > 1
            ? _c(
                "div",
                { staticClass: "formula-header flex-align-center" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(detail.salDetailName)),
                  ]),
                  _c("a-switch", {
                    attrs: { size: "small" },
                    model: {
                      value: detail.enabled,
                      callback: function ($$v) {
                        _vm.$set(detail, "enabled", $$v)
                      },
                      expression: "detail.enabled",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          detail.enabled == 1
            ? _c(
                "div",
                { staticClass: "formula-content" },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "计算公式" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticClass: "line-input",
                          on: {
                            change: function ($event) {
                              return _vm.detailChange(detail)
                            },
                          },
                          model: {
                            value: detail.formulaValue,
                            callback: function ($$v) {
                              _vm.$set(detail, "formulaValue", $$v)
                            },
                            expression: "detail.formulaValue",
                          },
                        },
                        _vm._l(detail.formulaList, function (item, i) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.formulaValue,
                              on: {
                                click: function ($event) {
                                  detail.formulaIndex = i
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.formulaContent))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(detail.formulaList, function (formula) {
                    return _c(
                      "div",
                      { key: formula.formulaValue },
                      [
                        formula.formulaValue === detail.formulaValue
                          ? _vm._l(formula.configItemList, function (config) {
                              return _c("div", { key: config.configItemNo }, [
                                config.configType === "1"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label:
                                                config.configItemName + ":",
                                            },
                                          },
                                          [
                                            config.unitType === "%"
                                              ? _c("Percentage", {
                                                  staticClass: "line-input",
                                                  attrs: {
                                                    "decimal-separator": true,
                                                    max:
                                                      config.unitType === "%" &&
                                                      _vm.form.salaryItemNo !==
                                                        "KQ02"
                                                        ? 100
                                                        : 999999999,
                                                  },
                                                  model: {
                                                    value: config.configValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        config,
                                                        "configValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "config.configValue",
                                                  },
                                                })
                                              : _c("CbNumber", {
                                                  staticClass: "line-input",
                                                  attrs: {
                                                    "decimal-separator": true,
                                                    suffix: config.unitType,
                                                  },
                                                  model: {
                                                    value: config.configValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        config,
                                                        "configValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "config.configValue",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : ["2", "3"].includes(config.configType)
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label:
                                                config.configItemName + ":",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "set-rules" },
                                              [
                                                _vm._l(
                                                  config.paraList,
                                                  function (it, index) {
                                                    return _c(
                                                      "div",
                                                      { key: index },
                                                      [
                                                        config.configItemNo ===
                                                        "wxbbtbz"
                                                          ? _c("CbTimePicker", {
                                                              staticClass:
                                                                "x-margin-12 margin-left-none",
                                                              attrs: {
                                                                options:
                                                                  _vm.timeSetUp,
                                                              },
                                                              model: {
                                                                value:
                                                                  it.startValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      it,
                                                                      "startValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "it.startValue",
                                                              },
                                                            })
                                                          : _c("CbNumber", {
                                                              staticClass:
                                                                "small-input margin-left-none",
                                                              model: {
                                                                value:
                                                                  it.startValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      it,
                                                                      "startValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "it.startValue",
                                                              },
                                                            }),
                                                        _vm._v(" <"),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "range-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                config.indexItem
                                                                  .indexItemName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v("≤ "),
                                                        config.configItemNo ===
                                                        "wxbbtbz"
                                                          ? _c("CbTimePicker", {
                                                              staticClass:
                                                                "x-margin-12",
                                                              attrs: {
                                                                options:
                                                                  _vm.timeSetUp,
                                                              },
                                                              model: {
                                                                value:
                                                                  it.endValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      it,
                                                                      "endValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "it.endValue",
                                                              },
                                                            })
                                                          : _c("CbNumber", {
                                                              staticClass:
                                                                "small-input",
                                                              model: {
                                                                value:
                                                                  it.endValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      it,
                                                                      "endValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "it.endValue",
                                                              },
                                                            }),
                                                        _vm._v(
                                                          _vm._s(
                                                            config.configItemName
                                                          ) + " "
                                                        ),
                                                        config.unitType === "%"
                                                          ? _c("Percentage", {
                                                              staticClass:
                                                                "number-input",
                                                              attrs: {
                                                                "decimal-separator": true,
                                                                max: 100,
                                                              },
                                                              model: {
                                                                value:
                                                                  it.configValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      it,
                                                                      "configValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "it.configValue",
                                                              },
                                                            })
                                                          : _c("CbNumber", {
                                                              staticClass:
                                                                "number-input",
                                                              attrs: {
                                                                "decimal-separator": true,
                                                                suffix:
                                                                  config.unitType,
                                                              },
                                                              model: {
                                                                value:
                                                                  it.configValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      it,
                                                                      "configValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "it.configValue",
                                                              },
                                                            }),
                                                        config.paraList.length >
                                                        1
                                                          ? _c("a-button", {
                                                              staticClass:
                                                                "close-circle",
                                                              attrs: {
                                                                type: "iconBtn",
                                                                icon: "close-small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteParaList(
                                                                      config,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "config-list-btn flex-align-center",
                                                  },
                                                  [
                                                    _c("a-button", {
                                                      staticClass:
                                                        "plus-circle",
                                                      attrs: {
                                                        type: "iconBtn",
                                                        icon: "add-one",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return config.paraList.push(
                                                            {}
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : config.configType === "4"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label:
                                                config.configItemName + ":",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "set-rules" },
                                              [
                                                _vm._l(
                                                  config.paraList,
                                                  function (it, index) {
                                                    return _c(
                                                      "div",
                                                      { key: index },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              config.indexItem
                                                                .indexItemName
                                                            ) +
                                                            " "
                                                        ),
                                                        config.indexItem
                                                          .indexDataType === "L"
                                                          ? [
                                                              _c(
                                                                "DictionariesInput",
                                                                {
                                                                  staticClass:
                                                                    "number-input",
                                                                  attrs: {
                                                                    parameter:
                                                                      config
                                                                        .indexItem
                                                                        .indexCode,
                                                                    placeholder:
                                                                      "请选择" +
                                                                      config
                                                                        .indexItem
                                                                        .indexItemName,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      it.fixedValue,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          it,
                                                                          "fixedValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "it.fixedValue",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          : config.indexItem
                                                              .indexDataType ===
                                                            "N"
                                                          ? [
                                                              _c("CbNumber", {
                                                                staticClass:
                                                                  "number-input",
                                                                attrs: {
                                                                  "decimal-separator": true,
                                                                  suffix:
                                                                    config.unitType,
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.fixedValue,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "fixedValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.fixedValue",
                                                                },
                                                              }),
                                                            ]
                                                          : config.indexItem
                                                              .indexDataType ===
                                                            "C"
                                                          ? [
                                                              _c("a-input", {
                                                                staticClass:
                                                                  "number-input",
                                                                model: {
                                                                  value:
                                                                    it.fixedValue,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "fixedValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.fixedValue",
                                                                },
                                                              }),
                                                            ]
                                                          : _vm._e(),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              config.configItemName
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("CbNumber", {
                                                          staticClass:
                                                            "number-input",
                                                          attrs: {
                                                            "decimal-separator": true,
                                                            suffix:
                                                              config.unitType,
                                                          },
                                                          model: {
                                                            value:
                                                              it.configValue,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                it,
                                                                "configValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "it.configValue",
                                                          },
                                                        }),
                                                        config.paraList.length >
                                                        1
                                                          ? _c("a-button", {
                                                              staticClass:
                                                                "close-circle",
                                                              attrs: {
                                                                type: "iconBtn",
                                                                icon: "close-small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteParaList(
                                                                      config,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "config-list-btn flex-align-center",
                                                  },
                                                  [
                                                    _c("a-button", {
                                                      attrs: {
                                                        type: "iconBtn",
                                                        icon: "add-one",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return config.paraList.push(
                                                            {}
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : config.configType === "5"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label:
                                                config.configItemName + ":",
                                            },
                                          },
                                          [
                                            _c("DictionariesInput", {
                                              staticClass: "line-input",
                                              attrs: {
                                                parameter:
                                                  config.configListCode,
                                              },
                                              model: {
                                                value: config.configListValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    config,
                                                    "configListValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.configListValue",
                                              },
                                            }),
                                            config.configItemNo === "jxjs" &&
                                            config.configListValue === "3"
                                              ? [
                                                  _c("CbNumber", {
                                                    staticClass: "number-input",
                                                    attrs: {
                                                      "decimal-separator": true,
                                                      suffix: "元",
                                                    },
                                                    model: {
                                                      value: config.configValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          config,
                                                          "configValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "config.configValue",
                                                    },
                                                  }),
                                                ]
                                              : _vm._e(),
                                            config.configItemNo === "zsbz"
                                              ? [
                                                  config.configListValue === "5"
                                                    ? _c("CbNumber", {
                                                        staticClass:
                                                          "number-input margin-right-none",
                                                        attrs: {
                                                          max: "31",
                                                          "decimal-separator": true,
                                                          "is-zero": false,
                                                          suffix: "天",
                                                        },
                                                        model: {
                                                          value:
                                                            config
                                                              .configListValueAdd[1],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              config.configListValueAdd,
                                                              1,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "config.configListValueAdd[1]",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            })
                          : _vm._e(),
                        formula.formulaValue === detail.formulaValue
                          ? [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "公式说明" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(formula.formulaDesc) + " "
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "plus-circle x-margin-12",
                                      attrs: { prefix: "bendijisuan" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.formulaBudget(
                                            detail,
                                            formula
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("公式测算")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  detail.needPersonalDeduct && detail.formulaValue
                    ? _c(
                        "div",
                        [
                          _vm.form.salaryItemNo === "KQ01" ||
                          (_vm.form.salaryItemNo === "KQ04" &&
                            !["C", "D"].includes(detail.formulaValue))
                            ? _c(
                                "a-form-model-item",
                                { attrs: { label: "个性化" } },
                                [
                                  _vm.form.salaryItemNo === "KQ01"
                                    ? [
                                        _vm._v(
                                          " 每月累计" +
                                            _vm._s(detail.salDetailName) +
                                            " "
                                        ),
                                        _c("CbNumber", {
                                          staticClass: "number-input",
                                          attrs: {
                                            "decimal-separator": true,
                                            suffix: "小时",
                                          },
                                          model: {
                                            value: detail.personalDeduct[0],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                detail.personalDeduct,
                                                0,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detail.personalDeduct[0]",
                                          },
                                        }),
                                        _vm._v(" 不扣款 "),
                                      ]
                                    : _vm._e(),
                                  _vm.form.salaryItemNo === "KQ04" &&
                                  ["1", "2"].includes(detail.salDetailValue)
                                    ? [
                                        ["E", "H"].includes(detail.formulaValue)
                                          ? _c(
                                              "div",
                                              [
                                                _vm._v(
                                                  " 每月累计" +
                                                    _vm._s(
                                                      detail.salDetailName
                                                    ) +
                                                    " "
                                                ),
                                                _c("CbNumber", {
                                                  staticClass: "number-input",
                                                  attrs: {
                                                    "decimal-separator": true,
                                                    suffix:
                                                      "" +
                                                      (detail.formulaValue ===
                                                      "E"
                                                        ? "分钟"
                                                        : "次"),
                                                  },
                                                  model: {
                                                    value:
                                                      detail.personalDeduct[0],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        detail.personalDeduct,
                                                        0,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detail.personalDeduct[0]",
                                                  },
                                                }),
                                                _vm._v(" 不扣款 "),
                                                detail.formulaValue === "E"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "，如时长超出则按超出时长扣款"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : [
                                              "A",
                                              "B",
                                              "F",
                                              "G",
                                              "I",
                                              "J",
                                            ].includes(detail.formulaValue)
                                          ? _c(
                                              "div",
                                              [
                                                _vm._v(
                                                  " 每月累计" +
                                                    _vm._s(
                                                      detail.salDetailName
                                                    ) +
                                                    " "
                                                ),
                                                _c("CbNumber", {
                                                  staticClass: "number-input",
                                                  attrs: {
                                                    "decimal-separator": true,
                                                    suffix: "次",
                                                  },
                                                  model: {
                                                    value:
                                                      detail.personalDeduct[0],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        detail.personalDeduct,
                                                        0,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detail.personalDeduct[0]",
                                                  },
                                                }),
                                                _vm._v(
                                                  " ，且每次迟到分钟数在 "
                                                ),
                                                _c("CbNumber", {
                                                  staticClass: "number-input",
                                                  attrs: {
                                                    "decimal-separator": true,
                                                    suffix: "分钟",
                                                  },
                                                  model: {
                                                    value:
                                                      detail.personalDeduct[1],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        detail.personalDeduct,
                                                        1,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detail.personalDeduct[1]",
                                                  },
                                                }),
                                                _vm._v(
                                                  " 内不扣款（按迟到先后统计） "
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ])
      }),
      _c("FormulaBudget", { ref: "formulaBudget" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }